import '../styles/globals.css';
import '../styles/burger.css';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SessionProvider } from 'next-auth/react';

function MyApp({ Component, pageProps }) {
  return (
    <SessionProvider options={{ clientMaxAge: 0 }} session={pageProps.session}>
      <SimpleReactLightbox>
        <Component {...pageProps} />
      </SimpleReactLightbox>
    </SessionProvider>
  );
}

export default MyApp;
